import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { RiAlarmWarningLine } from "react-icons/ri"
import { RiFileSettingsLine } from "react-icons/ri"
import { RiLightbulbLine } from "react-icons/ri"
import { RiTeamFill } from "react-icons/ri"
// import Cases from "../components/cases"
import Banner from "../components/hero/hero-ples.js"
// import Testimonials from "../components/swiper/testimonials-new"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import audit from "../images/new-images/application-audit.jpg"
import document from "../images/new-images/document-training.jpg"
import admin from "../images/new-images/admin-document.jpg"
import advance from "../images/new-images/advance-application.jpg"
import technical from "../images/new-images/advance-technical.jpg"
import tech from "../images/new-images/tech-debt.jpg"
import live from "../images/new-images/go-live-support.jpg"
import data from "../images/new-images/data-import.jpg"

const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)

const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"), 500)
)

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

class PlesPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Post Launch Enablement Services | Quandary"
          description="We provide the ongoing support, documentation, and user training your team needs to maximize the business value of your Quickbase applications."
        />

        <PartnerSwiper />

        <LazyLoadComponent>
          <section
            id="it-consulting"
            className="it-consulting"
            style={{ padding: "3rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-8 mx-auto text-center">
                  <h2 className="mb-2 mb-2 font-weight-medium">
                    Feature Launch? Adoption Issues? Weak Documentation? <br />{" "}
                    Difficulty Training? We’ve Got You Covered.
                  </h2>
                  <p className="mt-4">
                    Our Post Launch Enablement Services get your team excited
                    and confident about using your new applications.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                  marginTop: "3rem",
                }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-2">
                  <h3>Application Audit</h3>
                  <p className="mt-4">
                    We find bugs and prioritize your application issues,
                    starting with the highest-value improvements. We require
                    this step for all new clients, as it allows our team to
                    evaluate your existing tools and application infrastructure
                    before providing ongoing support.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/application-audit.jpg"
                    alt="Application Audit"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={audit} className="img-fluid mobile-appear-img" />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/document-training.jpg"
                    alt="End User Documentation and Training"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={document} className="img-fluid mobile-appear-img" />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>End User Documentation and Training</h3>
                  <p className="mt-4">
                    We collect user feedback to find out why user adoption
                    failed, reporting findings to stakeholders along with
                    recommendations. To help improve adoption rates, our
                    consultants host training sessions and office hours to walk
                    your team through the new tools and systems.
                  </p>
                  <p className="mt-4">
                    We also create PPT/PDF visual guides outlining: Step-by-step
                    processes for end users, general navigational/issue
                    escalation processes, and other supporting documents.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-2">
                  <h3>Admin User Documentation and Training</h3>
                  <p className="mt-4">
                    Our Live Training Sessions and Office Hours, led by our
                    consultants, show your admins how to use applications
                    safely. We also provide your team with visual guides and
                    documentation, ensuring they enforce permissions, run basic
                    tool maintenance, and properly support end-users.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/admin-document.jpg"
                    alt="Admin User Documentation and Training"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={admin} className="img-fluid mobile-appear-img" />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/advance-application.jpg"
                    alt="Advanced Application Training"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={advance} className="img-fluid mobile-appear-img" />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Advanced Application Training</h3>
                  <p className="mt-4">
                    We provide technical training for Client IT teams, covering
                    app functionality and maintenance of their Quickbase
                    applications and any associated integrations. This includes
                    assistance with data governance best practices, access
                    management, and other necessary support personnel functions.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-2">
                  <h3>Advanced Technical Design Documentation</h3>
                  <p className="mt-4">
                    Need technical design documentation, including comments
                    within code pages to remain compliant with your IT policies?
                    App structure outlining? Data movement? Automation? Our team
                    will get you the detailed documentation you need to improve
                    your team’s application use.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/advance-technical.jpg"
                    alt="Advanced Technical Design Documentation"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img
                    src={technical}
                    className="img-fluid mobile-appear-img"
                  />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3 p-">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/tech-debt.jpg"
                    alt="Tech Debt Assessment and Clean-Up"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={tech} className="img-fluid mobile-appear-img" />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Tech Debt Assessment and Clean-Up</h3>
                  <p className="mt-4">
                    We’ll find Quick-Fix Tech Debt and offer remediation
                    suggestions. For example: Removing deprecated or unconnected
                    tables, fields, reports, dashboards, homepages, forms, and
                    roles
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-2">
                  <h3>Rollout and Go-Live Support</h3>
                  <p className="mt-4">
                    We provide end-user support for UI interaction and access
                    during your new application and feature rollouts.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/go-live-support.jpg"
                    alt="Rollout and Go-Live Support"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={live} className="img-fluid mobile-appear-img" />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex mobile-hidden-img"
                    src="../images/new-images/data-import.jpg"
                    alt="Legacy Data Import"
                    layout="constrained"
                    loading="lazy"
                  />
                  <img src={data} className="img-fluid mobile-appear-img" />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Legacy Data Import</h3>
                  <p className="mt-4">
                    Get support importing clean legacy data into your new
                    applications using the native Quickbase import
                    functionality.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <Testimonials />

        <section
          className="bg-white quandary-connect-glance"
          style={{ padding: "3rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Do You Need Post Launch Enablement Support?</h2>
                <p>Our Post-Launch Enablement Support is right for you if:</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiFileSettingsLine />
                <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                  You Have Existing Quickbase Applications
                </h3>
                <p>
                  Post-Launch Enablement Support supports organizations with
                  existing Quickbase applications. Both new and legacy tools.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiAlarmWarningLine />
                <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                  You Have Application Performance Issues
                </h3>
                <p>
                  Your team struggles to use the applications and/or the
                  applications have critical issues that hamper performance and
                  adoption.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiLightbulbLine />
                <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                  You Need Immediate, Expert Support
                </h3>
                <p>
                  Your business relies on these applications to support
                  operations. Any downtime would damage customer relationships
                  and team efficiency.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiTeamFill />
                <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Your Team Needs Upskilling
                </h3>
                <p>
                  Your team needs guidance, documentation, and support to
                  effectively manage the new applications.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-indigo"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center text-left-mobile">
                <div>
                  <h2 className="text-white">
                    Maximize Your App and Team Performance
                  </h2>
                  <p className="text-white">
                    Get custom application support for your team and Quickbase
                    applications. Within your budget. Without Surprises.
                  </p>
                  <Link
                    className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                    to="/contact/"
                  >
                    Enable Your Team
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default PlesPage

export const plesPageQuery = graphql`
  query plesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
